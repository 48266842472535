import React from "react";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
//import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import ComponentProductHalLimb from "../../../components/main/component-product-hal-limb";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const ProductsNonMedicalDevice = ({ data, location }) => {
  const intl = useIntl();

  return (
    <Layout location={location} mode="beige">
      <Seo
        title={
          intl.locale === "en"
            ? `The World first neurologically controlled wearable cyborg - HAL Lower Limb`
            : `Der weltweit erste neurologisch kontrollierte Cyborg - HAL Lower Limb`
        }
        description={
          intl.locale === "en"
            ? `Hybrid Assistive Limb is a one-of-its-kind in its field, which is capable of detecting bio-electrical signals  and consolidates various information to complete voluntary movement of patients.`
            : `Der einzigartige Hybrid Assistive Limb kann bioelektrische Signale erkennen und bezieht verschiedenste Infomationen ein, um die willentliche Bewegung zu vollenden.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/1RUh1iXi7mPs20uYk8yjFr/a250d65619a26524a3880a47d6091da6/E170_172.JPG?w=1200&h=630&fit=fill"
      />
      <section className="sect sect-wide bg-hellblau">
        <StaticImage
          src="../../../images/products/hal-limb/header-lower-limb-type@2x.png"
          className="sect_Product__catch___img2 bg-absolute d-sm-none bg-sm-relative"
          alt=""
        />
        <StaticImage
          src="../../../images/products/hal-limb/header-lower-limb-type-mobile@2x.png"
          className="sect_Product__catch___img2 bg-absolute d-sm-block bg-sm-relative"
          alt=""
        />
        <div className="container flex-row my-7">
          <div className="flex-column-6">
            <h1 className="h1">
              {intl.locale === "en"
                ? `More Sense in Neurorehabilitation`
                : `Mehr Sinnesempfindung in der Neurorehabilitation`}
            </h1>
            <p className="message-label-bold txt txt-rot">
              HAL Lower Limb (HAL-ML05)
            </p>
            <p>
              {intl.locale === "en"
                ? `HAL Lower Limb Type is cleared as a medical device, obtaining CE
  Marking [CE 0197], in the EU for patients of Musculoskeletal
  Ambulation Disability Symptom Complex [= MADS], such as spinal
  cord injuries, traumatic brain injuries, cerebrovascular diseases,
  and other diseases of the brain and neuromuscular systems`
                : `Der HAL Lower Limb Type hat als Medizinprodukt eine CE-Kennung [CE 0197] und ist in der EU für Patienen die an einem muskuloskelettalen Ambulationsbehinderungs-Symptom-Komplex [= MADS] leiden, z.B. Rückenmarksverletzungen, traumatische Hirnverletzungen, zerebrovaskuläre Erkrankungen, Erkrankungen des Gehirns und des neuromuskulären Systems zugelassen.`}
            </p>
          </div>
        </div>
      </section>

      <section className="sect p-0 bg-bls">
        <div className="container flex-row mt-9 flex-wrap">
          <div className="flex-column-7 pr-4">
            <h2 className="fs-11">
              {intl.locale === "en"
                ? `Improve and regenerate physical functions`
                : `Verbessern und Regenerieren von körperlichen Funktionen`}
            </h2>
            <p className="mb-2">
              {intl.locale === "en"
                ? `HAL Lower Limb (HAL-ML05), a neurological device helps the
  patients regain the natural movement of their paretic or paralyzed
  legs based on their intention. It is to improve locomotor activity
  in spinal cord injury, stroke and other several types of
  neuromuscular disease patients even in the chronic stage of them.`
                : `HAL Lower Limb (HAL-ML05), ein neurologisches Gerät, hilft den Patienten, die natürliche Bewegung ihrer gelähmten Beine auf der Grundlage ihrer Absicht wiederzuerlangen. Es soll die Bewegungsaktivität von Patienten mit Rückenmarksverletzungen, Schlaganfall und anderen neuromuskulären Erkrankungen verbessern, auch im chronischen Stadium der Erkrankung.`}
            </p>
            <p className="mb-2">
              {intl.locale === "en"
                ? `The clinician has an array of customizable settings that can be
  used to help the patient carry out the movement they intended, or
  to present a challenge for the patient to overcome. Overtime with
  repetitive physiotherapy using HAL, the signals gradually improve
  in quality and quantity, leading to improved lower limb function.`
                : `Der Therapeut verfügt über eine Reihe von anpassbaren Einstellungen, die dem Patienten beim Ausführen der beabsichtigten Bewegung helfen oder eine Herausforderung für den Patienten darstellen, die er bewältigen muss. Bei wiederholter Physiotherapie mit HAL verbessern sich die Signale allmählich in Qualität und Quantität, was zu einer verbesserten Funktion der unteren Gliedmaßen führt.`}
            </p>
            <p className="mb-6">
              {intl.locale === "en"
                ? `Cybernics Therapy using HAL-ML05 guides and temporarily enforces
  an impaired neural loop, and restores physical ability.`
                : `Die Cybernic-Therapie mit HAL-ML05 führt und verstärkt vorübergehend eine gestörte neuronale Schleife und stellt die körperliche Leistungsfähigkeit wieder her.`}
            </p>
            <h2 className="txt txt-bla">
              {intl.locale === "en"
                ? `Indication of use`
                : `Indikation für die Verwendung`}
            </h2>

            {intl.locale === "en" ? (
              <ul className="mb-2">
                <li>
                  Spinal cord injury at levels T8 to L5 (ASIA A/B/C/D) and
                  levels C2 to T7 (ASIA C/D)
                </li>
                <li>Paralysis after cerebrovascular disease (CVD) </li>
                <li>
                  Progressive intractable neuromuscular diseases: Spinal
                  Muscular Atrophy (SMA), Spinal Bulbar Muscular Atrophy (SBMA),
                  Amyotrophic Lateral Sclerosis (ALS), Muscular Dystrophy (MD),
                  Inclusion Body Myositis (iBM), Charcot-Marie-Tooth Disease
                  (CMT), congenital Myopathy, distal Myopathy
                </li>
              </ul>
            ) : (
              <ul className="mb-2">
                <li>
                  Rückenmarksverletzungen in den Ebenen T8 bis L5 (ASIA A/B/C/D)
                  und in den Ebenen C2 bis T7 (ASIA C/D)
                </li>
                <li>Lähmungen nach zerebrovaskulären Erkrankungen (CVD)</li>
                <li>
                  Fortschreitende, nicht heilbare neuromuskuläre Erkrankungen:
                  Spinale Muskelatrophie (SMA), Spinale Bulbäre Muskelatrophie
                  (SBMA), Amyotrophe Lateralsklerose (ALS), Muskeldystrophie
                  (MD), Einschlusskörper-Myositis (iBM),
                  Charcot-Marie-Tooth-Krankheit (CMT), kongenitale Myopathie,
                  distale Myopathie
                </li>
              </ul>
            )}
            <p>
              {intl.locale === "en"
                ? `HAL-Lower Limb (HAL-ML) obtained CE Marking (CE 0197) for the
  first time as a robotic medical device. HAL for Medical Use was
  certified its conformity to the requirements of Medical Device
  Directives in EU. Prescription and medical professional
  supervision are required on usage of HAL system.`
                : `HAL Lower Limb (HAL-ML) erhielt erstmals die CE-Kennzeichnung (CE 0197) für ein robotisches Medizinprodukt. HAL für medizinische Zwecke wurde die Konformität mit den Anforderungen der Medizinprodukterichtlinien der EU bescheinigt. Für die Verwendung des HAL-Systems sind eine Verschreibung und die Überwachung durch medizinisches Fachpersonal erforderlich.`}
            </p>
          </div>
          <div className="flex-column-5">
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Functional improvement and regeneration`
                : `Funktionelle Verbesserung und Regeneration`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Reduced need for assistive devices`
                : `Reduktion der Hilfsmittel`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Increased walking speed and walking distance`
                : `Erhöhte Gehgeschwindigkeit und längere Gehstrecke`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Development of musculature`
                : `Entwicklung der Muskulatur`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Prolong a high-quality of life`
                : `Lebensqualität erhalten`}
            </p>
          </div>
          <div className="flex-column-12 mt-8">
            <div className="flex-row mx-n2 mb-6 pict-3">
              <div className="px-2">
                <StaticImage
                  src="../../../images/products/hal-limb/image-hal-1@2x.png"
                  className="mb-4"
                  alt=""
                />
              </div>
              <div className="px-2">
                <StaticImage
                  src="../../../images/products/hal-limb/image-hal-2@2x.png"
                  className="mb-4"
                  alt=""
                />
              </div>
              <div className="px-2">
                <StaticImage
                  src="../../../images/products/hal-limb/image-hal-3@2x.png"
                  className="mb-4"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sect pb-0">
        <div className="container">
          <div className="flex-row">
            <div className="flex-column-6">
              <h2 className="txt txt-rot">
                {intl.locale === "en"
                  ? `HAL Monitor for Better Care Wirelessly display the neurological
  signal on a tablet screen in real time.`
                  : `HAL Monitor für eine bessere Betreuung und kabellose Anzeige des neurologischen Signals auf einem Tablet-Bildschirm in Echtzeit.`}
              </h2>
              {intl.locale === "en" ? (
                <p>
                  HAL Monitor provides numeral and visual information and
                  feedbacks, in real time, on physical and neurological of
                  wearers. Patients and therapists can choose 4 different views
                  such as summary view, graph view, foot view, and model view.
                  These views offer a powerful tool to understand where patients
                  need to work on.
                  <br />
                  HAL Lower Limb has four sets of sensors with each set
                  detecting signals from the flexor and extensor muscles around
                  a specific joint. The four joints assisted by HAL include the
                  hip and knee joints of each leg. Those neurological signals
                  are displayed on a tablet device in real time, allowing
                  therapists to analyze individual joint activity as well as the
                  overall interactions between joints, and to modify HAL’s
                  settings for the best results.
                </p>
              ) : (
                <p>
                  Der HAL Monitor liefert numerische und visuelle Informationen
                  und Rückmeldungen in Echtzeit über den physischen und
                  neurologischen Zustand des Trägers. Patienten und Therapeuten
                  können zwischen vier verschiedenen Ansichten wählen, z.B. der
                  Übersichtsansicht, der Grafikansicht, der Fußansicht und der
                  Modellansicht. Diese Ansichten sind ein leistungsfähiges
                  Instrument, um zu verstehen, woran die Patienten arbeiten
                  sollten.
                  <br />
                  HAL Lower Limb verfügt über vier Sensorensätze, von denen
                  jeder die Signale der Beuger- und Streckermuskeln um ein
                  bestimmtes Gelenk herum erfasst. Zu den vier Gelenken, die von
                  HAL unterstützt werden, gehören die Hüft- und Kniegelenke der
                  beiden Beine. Diese neurologischen Signale werden in Echtzeit
                  auf einem Tablet angezeigt, so dass der Therapeut die
                  Aktivität der einzelnen Gelenke sowie die Gesamtinteraktion
                  zwischen den Gelenken analysieren und die Einstellungen von
                  HAL ändern kann, um optimale Ergebnisse zu erzielen.
                </p>
              )}
            </div>
            <div className="flex-column-6">
              <StaticImage
                src="../../../images/products/hal-limb/26.jpg"
                className="ml-2"
              />
            </div>
          </div>
        </div>
      </section>

      <ComponentProductHalLimb
        image="/images/treatment/treatment-slider-img-1.png"
      />

      {/* <section className="sect p-0 sect_Product__non-medical-device-slider">
      <div className="container flex-row sect_Product__non-medical-device-slider__text">
        <div className="flex-column-6 pl-4">
          <p className="fs-6 fwb bt-2-white pt-2 w-300px">The Science</p>
        </div>
        <div className="flex-column-6 mt-8">
          <div className="slide">
          <Slider {...settings}>
            <div className="slide__link">
              <h2 className="fs-11">Hybrid assistive limb (HAL) for Care Support can reduce lumbar load in care deliveries</h2>
              <p className="fs-4">Successful Use of the Hybrid Assistive Limb for Care Support to Reduce Lumbar Load in a Simulated Patient Transfer - The HAL for Care Support significantly reduced subjective lumbar fatigue in a simulated patient transfer. Use of the HAL for Care Support may reduce the occurrence of low back disorders.</p>
            </div>
            <div className="slide__link">
              <h2 className="fs-11">Subjects were able to perform significantly more lifting cycles using HAL</h2>
              <p className="fs-4">Muscle Synergies During Repetitive Stoop Lifting With a Bioelectrically-Controlled Lumbar Support Exoskeleton - The results indicate that muscle coordination patterns are significantly changed, mainly in the timing coefficients of the synergies and marginally changed in synergy weights, when subjects are using an exoskeleton (HAL).</p>
            </div>
            <div className="slide__link">
              <h2 className="fs-11">Biofeedback exercise with HAL-Lumbar Type is a safe and promising treatment for frailty patients</h2>
              <p className="fs-4">Biofeedback Core Exercise Using Hybrid Assistive Limb for Physical Frailty Patients With or Without Parkinson's Disease - Motor dysfunction in Parkinson's disease patients may be partly due to physical frailty, and biofeedback therapy with HAL-Lumbar Type is proposed as a treatment option. Immediate and sustained effects on patients who were refractory to conventional rehabilitation could provide evidence that changes in input to specific receptors by HAL- Lumbar Type contributes to activation of disused neuronal networks and amelioration of arthrogenic muscle inhibition. </p>
            </div>
            <div className="slide__link">
              <h2 className="fs-11">Significantly reduces cardiopulmonary burden during squat exercise in healthy subjects</h2>
              <p className="fs-4">Effects of a lumbar-type hybrid assistive limb on cardiopulmonary burden during squat exercise in healthy subjects - The oxygen uptake (VO2) and the Borg Scale rating were significantly lower when HAL was used. The HAL significantly reduces cardiopulmonary burden during squat exercise</p>
            </div>
            <div className="slide__link">
              <h2 className="fs-11">Significantly decreased subjective lumbar fatigue</h2>
              <p className="fs-4">The hybrid assistive limb (HAL) for Care Support successfully reduced lumbar load in repetitive lifting movements – HAL- Lumbar Type can decrease lumbar load and improve the lifting performance during repetitive stoop lifting movements in healthy adults.</p>
            </div>
            <div className="slide__link">
              <h2 className="fs-11">Significantly improved the snow-shoveling performance</h2>
              <p className="fs-4">The hybrid assisted limb (HAL) for Care Support, a motion assisting robot providing exoskeletal lumbar support, can potentially reduce lumbar load in repetitive snow-shoveling movements - The reduction of lumbar fatigue and improvement of snow-shoveling performance using HAL for Care Support were statistically significant.</p>
            </div>
          </Slider>
          </div>
        </div>
      </div>
      <div className="bg-rot sect_Product__non-medical-device-slider__bg" />
      <StaticImage src="../../../images/products/motion-principal-big-teaser.png" className="sect_Product__non-medical-device-slider__image"/>
    </section> */}

      <section className="p-0 py-120px position-relative">
        <div className="container">
          <div className="container__inner flex-row">
            <div className="flex-column-6">
              <h2>
                {intl.locale === "en" ? `Specifications` : `Spezifikationen`}
              </h2>
              <div className="flex-row mb-4">
                <div className="flex-column-6">
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `External dimension`
                        : `Außenabmessungen`}
                    </p>
                    {intl.locale === "en" ? (
                      <p className="">
                        Length 430mm
                        <br />
                        width 470mm
                        <br />
                        height 1,230mm
                      </p>
                    ) : (
                      <p className="">
                        Länge 430 mm
                        <br />
                        Breite 470 mm
                        <br />
                        Höhe 1.230 mm
                      </p>
                    )}
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Weight (Double-leg model)`
                        : `Gewicht (Modell mit zwei Beinen)`}
                    </p>
                    <p className="">{intl.locale === "en" ? `14kg` : `14kg`}</p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Weight (Single-leg model)`
                        : `Gewicht (einbeiniges Modell)`}
                    </p>
                    <p className="">{intl.locale === "en" ? `9kg` : `9kg`}</p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Movable range (hip joint)`
                        : `Beweglicher Bereich Hüftgelenk`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Extension 20˚, Flexion 120˚`
                        : `Streckung 20˚, Beugung 120˚`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Movable range (knee joint)`
                        : `Beweglicher Bereich Kniegelenk`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Extension 6˚, Flexion 120˚`
                        : `Streckung 6˚, Beugung 120˚`}
                    </p>
                  </div>
                </div>
                <div className="flex-column-6">
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Wight limit` : `Gewichtsgrenze`}
                    </p>
                    <p className="">
                      {intl.locale === "en" ? `40kg–100kg` : `40 bis 100 kg`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Operating time`
                        : `Betriebsdauer`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Approximately 1 hour`
                        : `Ungefähr eine Stunde`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Power source` : `Stromquelle`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Custom battery`
                        : `eigene Batterie`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <StaticImage
          src="../../../images/products/hal-limb/hal-lower-limb@2x.png"
          className="bg-absolute"
        />
      </section>

      <section className="sect sect-qa bg-bge">
        <div className="container">
          <p className="h2 mb-5">
            {intl.locale === "en"
              ? `Learn more about our products`
              : `Erfahren Sie mehr über unsere Produkte`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  Motion Principle of HAL
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL can sensor faint BES that trickle onto the skin, process
  it, and simultaneously assist one’s desired movement with
  motors located on each hip and knee joint.`
                    : `HAL kann kleinste bioelektrische Signale auf der Haut erkennen, diese verarbeiten und gleichzeitig die beabsichtige Bewegung  durch Motoren an jedem Hüft- und Kniegelenk unterstützen. `}
                </p>
                <Link
                  to="/products/medical-device/hal-motion-principal/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            {/* <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lower Limb Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Think, Send, Read, Move—HAL is the World first neurologically
  controlled wearable Cyborg which puts your intentions into
  action. HAL improves locomotor activity in stroke, spinal cord
  injury and other neuromuscular disease patients.`
                    : `Denken, Senden, Lesen, Bewegen - HAL ist der weltweit erste neurologisch kontrollierte tragbare Cyborg, der Ihre Absichten in Aktionen umsetzt. HAL verbessert die Bewegungsfähigkeit nach Schlaganfällen, Rückenmarksverletzungen und anderen neuromuskulären Erkrankungen.`}
                </p>
                <Link
                  to="/products/medical-device/hal-limb/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}                </Link>
              </AccordionItemPanel>
            </AccordionItem> */}
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Single Joint</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL Single Joint is a portable and lightweight unit which enables intensive and repetitive training of specific joints such as elbow, knee, and ankle.`
                    : `HAL Single Joint ist ein portables und leichtes Gerät, das ein intensives und wiederholtes Training eines speziellen Gelenkes, wie zum Beispiel Ellbogen, Knie und Sprunggelenk, ermöglicht.`}
                </p>
                <Link
                  to="/products/medical-device/hal-joint/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lumbar Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Improving a quality of life by ensuring your well-being, reducing fatigue in the work environment, regaining your strength and mobility, and expanding your capability.`
                    : `Verbesserung der Lebensqualität durch Sicherstellung Ihres Wohlbefindens, Reduzierung der Ermüdung im Arbeitsumfeld, Wiedererlangen Ihrer Kraft und Mobilität, sowie Ausbau Ihrer Leistungsfähigkeit.`}
                </p>
                <Link
                  to="/products/non-medical-device/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Cleaning Robot`
                    : `Reinigungsroboter`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Keeping large facilities such as airport, shopping malls,
  hotels, and conference centers clean has never been easier.
  Find out how our cleaning and disinfection robot keeps your
  space tidy without the need for human operation.`
                    : `Es war nie einfacher große Gebäude wie Flughäfen, Einkaufszentren, Hotels und Konferenzzentren sauber zu halten. Finden Sie heraus wie unser Reinigungsroboter Ihre Räume ohne menschliche Unterstützung sauber hält.`}
                </p>
                <div>
                  <Link to="/products/cleaning-robot/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>CYIN</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Ingenious interfaces for people including those suffer from
  progressive neurodegenerative disease. CYIN utilizes
  biological information to help communicate with ease, despite
  all their limitations.`
                    : `Geniale Schnittstelle für Menschen, einschließlich derer die an progressiven neurodegenerativen Erkrankungen leiden. CYIN nutzt biologische Informationen um die Kommunikation, unabhängig all Ihrer Einschränkungen, zu vereinfachen.`}
                </p>
                <div>
                  <Link to="/products/communication/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Acoustic X</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Acoustic X is photoacoustic imaging technology that helps
  detect melanin, lipid, collages, water, and contrast agents
  specifically designed to locate various biomarkers.`
                    : `Acoustic X ist eine photoakustische Bildgebungstechnologie, die Melanin, Fette, Kollagen und Kontrastmittel zur Lokalisation bestimmter Biomarker, erkennt.`}
                </p>
                <div>
                  <Link to="/products/imaging/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <section className="sect sect_Product__footer p-0">
        <div className="container py-8 my-8 flex-row">
          <div className="flex-column-6">
            <h2 className="h3">
              {intl.locale === "en"
                ? `HAL Lower Limb — The Gift of Mobility`
                : `HAL Lower Limb - Das Geschenk der Mobilität`}
            </h2>
            <p>
              {intl.locale === "en"
                ? `Improve the process of recovery one step at a time with Cyberdyne.`
                : `Verbessere deine Rehabilitation Schritt für Schritt mit Cyberdyne`}
            </p>
            <div>
              <Link to="/contact/" className="link_button">
                {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProductsNonMedicalDevice;
