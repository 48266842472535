import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  useIntl,
  Link,
  //FormattedMessage,
  //changeLocale,
} from "gatsby-plugin-react-intl";
import Slider from "react-slick";

const ComponentMain5 = ({ image, children }) => {
  const intl = useIntl();
  const [pos, setPos] = useState(1);
  //const isActiveBenefit = () => {
  //  return pos === 0 ? "active" : "";
  //};
  const isActiveScience = () => {
    return pos === 1 ? "active" : "";
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    //autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    slidesToShow: 1,
    //centerPadding: '100px',
    focusOnSelect: true,
    //centerMode: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 896,
        settings: {
          centerMode: false,
        },
      },
    ],
  };
  return (
    <section className="sect sect_TreatmentCvd_iuh234 bg-white">
      <div className="container mb-0 pb-0">
        <div className="container-block flex-row position-relative bg-rot-important top-border-container">
          <div className="right-all-bg" />
          <div className="flex-column-12">
            <div className="flex-row top-border-wrap mb-8">
              {/* <div className="flex-column-4 px-4">
                <div
                  className={"white top-border " + isActiveBenefit()}
                  onClick={() => setPos(0)}
                >
                  The Benefits:
                </div>
              </div> */}
              <div className="flex-column-4 px-4">
                <div
                  className={"white top-border " + isActiveScience()}
                  onClick={() => setPos(1)}
                  onKeyDown={() => setPos(1)}
                  role = "button"
                  tabIndex={0}
                >
                  {intl.locale === "en" ? `Sciences` : `Publikationen`}
                </div>
              </div>
            </div>
          </div>
          <div className="container-block-imgContainer flex-column-6">
            <StaticImage
              src="../../images/products/hal-limb/27.jpg"
              alt="ImgTreatment"
              loading="eager"
              className="Imgblock01"
            />
          </div>
          <div className="container-block-txtContainer flex-column-6">
            <div className="px-6 py-0">
              {pos === 0 ? (
                <div>
                  <h2 className="h3">
                    The neuromuscular feedback therapy leads to a significant
                    gain in functional locomotion. Other effects which can be
                    more or less pronounced include: Reduction of neuropathic
                    pains, improved bladder and bowl functions, positive
                    improved spasticity symptoms, larger areas with skin
                    sensation resulting in a lower decubitus risk.
                  </h2>
                  <p>
                    • Functional improvement and increase in mobility
                    <br />
                    • Reduced need for assistive devices
                    <br />
                    • Increased walking speed
                    <br />
                    • Increased walking distance
                    <br />
                    • Development of musculature
                    <br />• Increased sensitivity
                  </p>
                  <div>
                    <Link to="#" className="link-simple">
                      {intl.locale === "en" ? `Read` : `Lesen`}
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="slide slide-single slide-rot">
                  <Slider {...settings}>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Progressive rare neuromuscular diseases`
                          : `Seltene progressive neuromuskuläre Erkrankungen`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `HAL is a new treatment device for walking exercise, proven to be more effective than the conventional method in patients with incurable neuromuscular diseases.`
                          : `HAL ist ein neues Therapiegerät für Gehübungen. Bei Patienten mit unheilbaren neuromuskulären Krankheiten ist das HAL Training effektiver als die konventionelle Therapie.`}
                      </p>
                      <p className="fsi">
                        {intl.locale === "en"
                          ? `"Cybernic treatment with wearable cyborg Hybrid Assistive Limb (HAL) improves ambulatory function in patients with slowly progressive rare neuromuscular diseases: a multicentre, randomised, controlled crossover trial for efficacy and safety (NCY-3001) "`
                          : `"Cybernic treatment with wearable cyborg Hybrid Assistive Limb (HAL) improves ambulatory function in patients with slowly progressive rare neuromuscular diseases: a multicentre, randomised, controlled crossover trial for efficacy and safety (NCY-3001) "`}
                      </p>
                      <div>
                        <a
                          href="https://ojrd.biomedcentral.com/articles/10.1186/s13023-021-01928-9"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Spinal Cord Injury`
                          : `Rückenmarksverletztungen`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `Use of HAL Lower Limb for 55 chronic spinal cord injury
  patients for 12 weeks (5 times/week) resulted in
  significant improvement in walking speed, distance and
  level of dependence on walking aids without wearing the
  device.`
                          : `Die Verwendung von HAL Lower Limb bei 55 Patienten mit chronischen Rückenmarksverletzungen über einen Zeitraum von zwölf Wochen (fünfmal pro Woche) führte zu einer signifikanten Verbesserung der Gehgeschwindigkeit, der Gehstrecke und des Grades der Abhängigkeit von Gehhilfen, ohne dass das Gerät getragen wurde.`}
                      </p>
                      <p className="fsi">
                        {intl.locale === "en"
                          ? `"Against the odds: what to expect in rehabilitation of
  chronic spinal cord injury with a neurologically
  controlled Hybrid Assistive Limb exoskeleton. A subgroup
  analysis of 55 patients according to age and lesion
  level"`
                          : `"Against the odds: what to expect in rehabilitation of chronic spinal cord injury with a neurologically controlled Hybrid Assistive Limb exoskeleton. A subgroup analysis of 55 patients according to age and lesion level"`}
                      </p>
                      <div>
                        <a
                          href="https://thejns.org/focus/view/journals/neurosurg-focus/42/5/article-pE15.xml"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Subacute Stroke Recovery`
                          : `Genesung bei subakutem Schlaganfall`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `16 sub-acute stage stroke patients who reached plateau
  with traditional rehabilitation were split into
  experimental group with HAL intervention and control
  group with conventional physiotherapy for at least 20
  sessions and the result showed the HAL group
  significantly improved walking speed.`
                          : `16 Schlaganfallpatienten im subakuten Stadium, die mit der herkömmlichen Rehabilitation ein Plateau erreicht hatten, wurden in eine Versuchsgruppe mit HAL Intervention und eine Kontrollgruppe mit konventioneller Physiotherapie für mindestens 20 Sitzungen aufgeteilt. Die Ergebnisse zeigten, dass die HAL Gruppe die Gehgeschwindigkeit deutlich verbessert hatte.`}
                      </p>
                      <p className="fsi">
                        {intl.locale === "en"
                          ? `"Subacute Stroke Recovery Gait Training With Hybrid
  Assistive Limb Enhances the Gait Functions in Subacute
  Stroke Patients: A Pilot Study"`
                          : `"Subacute Stroke Recovery Gait Training With Hybrid Assistive Limb Enhances the Gait Functions in Subacute Stroke Patients: A Pilot Study"`}
                      </p>
                      <div>
                        <a
                          href="https://pubmed.ncbi.nlm.nih.gov/27814305/"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Cerebral Palsy Movement Research`
                          : `Bewegungsforschung in der Zerebralparese`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `This report concluded that: “HAL intervention and
  surgery with improvement of contractures of the knee and
  ankle can enhance improvement in walking ability for the
  patient with cerebral palsy. Improvement in walking
  ability leads to improved lower limb function, improved
  standing ability, and reduced need for assistance.” This
  has profound effects on what is possible for patients
  with cerebral palsy.`
                          : `Dieser Bericht kam zu dem Schluss, dass "eine HAL Intervention und eine Operation mit Verbesserung der Kontrakturen des Knies und des Knöchels die Gehfähigkeit eines Patienten mit Zerebralparese verbssern können. Die Verbesserung der Gehfähigkeit führt zu einer verbesserten Funktion der unteren Gliedmaßen, einer verbesserten Stehfähigkeit und einer geringeren Hilfsbedürftigkeit". Dies hat tiefgreifende Auswirkungen auf die Möglichkeiten von Patienten mit zerebraler Kinderlähmung.`}
                      </p>
                      <p className="fsi">
                        {intl.locale === "en"
                          ? `"Use of Hybrid Assistive Limb (HAL®) for a postoperative
  patient with cerebral palsy: a case report"`
                          : ` "Use of Hybrid Assistive Limb (HAL®) for a postoperative patient with cerebral palsy: a case report"`}
                      </p>
                      <div>
                        <a
                          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5870205/"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Knee Arthroplasty Recovery`
                          : `Genesung nach einer Knieendoprothese`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `This report concluded that: “HAL training after TKA can
  improve the walking ability, ROM, and muscle strength
  compared to conventional physical therapy for up to 8
  weeks after TKA.” Researchers have new recovery methods
  available to them with wearables in several different
  facets of post-surgery therapy.`
                          : `Diese Studie kam zu folgendem Schluss: "HAL Training nach TKA kann die Gehfähigkeit, die Gelenksbeweglichkeit und die Muskelkraft im Vergleich zu konventioneller Physiotherapie für bis zu acht Wochen nach TKA verbessern." Den Wissenschaftlern stehen mit Wearables in verschiedenen Bereichen der postoperativen Therapie neue Erholungsmethoden zur Verfügung.`}
                      </p>
                      <p className="fsi">
                        {intl.locale === "en"
                          ? `"Training with Hybrid Assistive Limb for walking
  function after total knee arthroplasty"`
                          : `"Training with Hybrid Assistive Limb for walking function after total knee arthroplasty" `}
                      </p>
                      <div>
                        <a
                          href="https://josr-online.biomedcentral.com/articles/10.1186/s13018-018-0875-1"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Gait Training with Bionics`
                          : `Gehtraining mit Bionics`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `The study concluded that: “This review identified
  consistent evidence that the use of the HAL system is
  feasible when used for gait training in hospital and
  rehabilitation settings. Data suggest that such training
  may have beneficial effects on gait function and
  independence in walking after stroke and after spinal
  cord injury.” Re-learning to walk is a difficult
  endeavor for both patient and physical therapist.
  Wearable technology may be one key to easing the
  difficulty and researchers will be at the forefront of
  this.`
                          : `Die Studie kam zu folgendem Schluss: "Diese Überprüfung ergab konsistente Beweise dafür, dass die Verwendung des HAL Systems für das Gehtraining in Krankenhäusern und Rehabilitationseinrichtungen machbar ist. Die Daten deuten darauf hin, dass ein solches Training positive Auswirkungen auf die Gangfunktion und die Unabhängigkeit beim Gehen nach einem Schlaganfall und nach einer Rückenmarksverletzung haben kann." Das Wiedererlernen des Gehens ist sowohl für den Patienten als auch für den Physiotherapeuten ein schwieriges Unterfangen. Wearable Technology könnte ein Schlüssel zur Erleichterung der Schwierigkeiten sein, und die Wissenschaftler werden dabei an vorderster Front stehen.`}
                      </p>
                      <p className="fsi">
                        {intl.locale === "en"
                          ? `"Clinical application of the Hybrid Assistive Limb (HAL)
  for gait training—a systematic review"`
                          : `"Clinical application of the Hybrid Assistive Limb (HAL) for gait training—a systematic review" `}
                      </p>
                      <div>
                        <a
                          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4373251/"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                  </Slider>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComponentMain5;
